import React, { useEffect, useState } from 'react';
import './FadeMenu.scss';
import Div100vh from 'react-div-100vh';

const Fade = ({ show, children }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <div
        className="wrapper"
        style={{ animation: `${show ? 'fadeIn 1s' : 'fadeOut 350ms'}` }}
        onAnimationEnd={onAnimationEnd}
      >
        <Div100vh>{children}</Div100vh>
      </div>
    )
  );
};

export default Fade;
