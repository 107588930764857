import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Spanify from '../Spanify';
import { LocaleContext } from '../Layout';
import './Menu.scss';

import Instagram from '../../images/instagram.svg';
import Twitter from '../../images/twitter.svg';
import Facebook from '../../images/facebook.svg';

const menu_items = {
  en: {
    about: 'About',
    blog: 'Blog',
    shop: 'Shop',
  },
  es: {
    about: 'Información',
    blog: 'Blog',
    shop: 'Negocio',
  },
};

const Menu = ({ closeMenu, location }) => {
  const [disableClicks, setDisableClicks] = useState(false);
  const [hoverAnimationActive, setHoverAnimationActive] = useState(false);
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    setTimeout(() => {
      setDisableClicks(true);
    }, 1300);
  }, []);

  return (
    <div
      className="menu"
      style={{
        pointerEvents: disableClicks ? 'auto' : 'none',
      }}
    >
      <div className="language-selector">
        <Link
          className={locale === 'en' ? 'active' : null}
          to={location.pathname.replace(/es/i, '')}
          state={{ disableAnimations: true }}
          onClick={closeMenu}
        >
          EN
        </Link>
        <Link
          className={locale === 'es' ? 'active' : null}
          to={
            location.pathname === '/'
              ? '/es'
              : !location.pathname.includes('/es')
              ? `es/${location.pathname}`
              : location.pathname
          }
          state={{ disableAnimations: true }}
          onClick={closeMenu}
        >
          ES
        </Link>
      </div>
      <div className="menu-content">
        <div className="menu-links">
          <div className="links-content">
            <div className="menu-galleries">
              <Link
                to={locale === 'en' ? '/reportage' : `${locale}/reportage`}
                onClick={closeMenu}
              >
                <h2
                  className={`rep-link ${
                    hoverAnimationActive ? 'hover-animation-active' : ''
                  }`}
                  onMouseEnter={() => setHoverAnimationActive(true)}
                >
                  <Spanify text="Reportage" />
                </h2>
              </Link>
              <Link
                to={locale === 'en' ? '/corporate' : `${locale}/corporate`}
                onClick={closeMenu}
              >
                <h2
                  className={`corp-link ${
                    hoverAnimationActive ? 'hover-animation-active' : ''
                  }`}
                  onMouseEnter={() => setHoverAnimationActive(true)}
                >
                  <Spanify text="Corporate" />
                </h2>
              </Link>
            </div>
            <span className="line"></span>
            <div className="menu-external-links">
              <Link
                to={locale === 'en' ? '/about' : `${locale}/about`}
                onClick={closeMenu}
              >
                <h3
                  className={`about-link ${
                    hoverAnimationActive ? 'hover-animation-active' : ''
                  }`}
                  onMouseEnter={() => setHoverAnimationActive(true)}
                >
                  <Spanify
                    text={
                      locale === 'en'
                        ? menu_items.en.about
                        : menu_items.es.about
                    }
                  />
                </h3>
              </Link>
              {/* <Link to="/" onClick={closeMenu}>
                <h3
                  className={`blog-link ${
                    hoverAnimationActive ? 'hover-animation-active' : ''
                  }`}
                  onMouseEnter={() => setHoverAnimationActive(true)}
                >
                  <Spanify
                    text={
                      locale === 'en' ? menu_items.en.blog : menu_items.es.blog
                    }
                  />
                </h3>
              </Link>
              <Link to="/" onClick={closeMenu}>
                <h3
                  className={`shop-link ${
                    hoverAnimationActive ? 'hover-animation-active' : ''
                  }`}
                  onMouseEnter={() => setHoverAnimationActive(true)}
                >
                  <Spanify
                    text={
                      locale === 'en' ? menu_items.en.shop : menu_items.es.shop
                    }
                  />
                </h3>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="menu-contacts">
          <p>
            <b>Diego Ravier</b> - Rue Fernand Bernier 25 / 1060 Brussels,
            Belgium
            <br />
            diegoravier@gmail.com +32 (0) 488292845
          </p>
          <p>
            <OutboundLink
              className="social-icon"
              href="https://www.instagram.com/diegoravier/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </OutboundLink>
            <OutboundLink
              className="social-icon"
              href="https://twitter.com/droitsreserves"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </OutboundLink>
            <OutboundLink
              className="social-icon"
              href="https://www.facebook.com/diego.ravier"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </OutboundLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
