import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
// import { useTransition, animated } from 'react-spring';
import { navigate } from 'gatsby';
import Fullscreen from 'react-full-screen';

import Nav from './Nav/Nav';
import Menu from './Menu/Menu';
import FadeMenu from './Menu/FadeMenu';
import PreloadImages from './PreloadImages';
import { AppProvider } from '../AppContext';

import useIphoneDetection from '../hooks/useIphoneDetection';

// import useRotation from '../hooks/useRotation';
// import RotateDevice from './RotateDevice/RotateDevice';

export const LocaleContext = React.createContext();

const Layout = ({ children, location, pageContext: { locale } }) => {
  // Query that fetches data for preloading the galleries previews
  const preload = useStaticQuery(
    graphql`
      query Preload {
        sections: allPrismicSection {
          edges {
            node {
              data {
                image {
                  url
                }
              }
            }
          }
        }
        galleries: allPrismicGallery {
          edges {
            node {
              data {
                body {
                  items {
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  // This will be an array of images that need to be preload -> passed to PreloadImages component
  const images = [];
  const sectionsEdges = preload.sections.edges;
  const galleryEdges = preload.galleries.edges;
  sectionsEdges.forEach(edge => {
    images.push(edge.node.data.image.url);
  });
  galleryEdges.forEach(edge => {
    images.push(edge.node.data.body[0].items[0].image.url);
  });

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const [isFullscreen, setFullscreen] = useState(false);

  const isIphone = useIphoneDetection();

  // const isLandscape = useRotation();
  // const rotate = useTransition(isLandscape, null, {
  //   config: {
  //     mass: 1,
  //     tension: 280,
  //     friction: 34,
  //     clamp: true,
  //     precision: 0.1,
  //   },
  //   from: { position: 'absolute', zIndex: 999, opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  // });

  useEffect(() => {
    let detectedLanguage =
      window.navigator.userLanguage || window.navigator.language;
    detectedLanguage = detectedLanguage.slice(0, 2);

    // Let's say we are from Spain and we open the home, we want to be redirected to the Spanish version
    if (location.pathname === '/' && detectedLanguage === 'es') {
      navigate('/es');
    }

    // Prevent pinch-to-zoom in ios
    const blockPinch = e => {
      if (e.scale && e.scale !== 1) e.preventDefault();
    };

    // Prevent swipe back gestures
    const blockSwipeBack = e => {
      if (e.pageX && (e.pageX < 10 || e.pageX > window.innerWidth - 10))
        e.preventDefault();
    };

    window.addEventListener('touchmove', blockPinch, { passive: false });
    window.addEventListener('touchstart', blockSwipeBack, { passive: false });
    return () => {
      window.removeEventListener('touchmove', blockPinch);
      window.removeEventListener('touchstart', blockSwipeBack);
    };
  }, []);

  useEffect(() => {
    if (isIphone) document.body.classList.add('iphone');
  }, [isIphone]);

  return (
    <LocaleContext.Provider value={{ locale }}>
      <AppProvider>
        <PreloadImages images={images} />
        {/* {rotate.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} style={props}>
                <RotateDevice />
              </animated.div>
            )
        )} */}
        <Fullscreen
          enabled={isFullscreen}
          onChange={isFullscreen => setFullscreen(isFullscreen)}
        >
          <Nav
            toggleMenu={() => setMenuIsVisible(prev => !prev)}
            showLogo={!menuIsVisible}
            location={location}
            setFullscreen={setFullscreen}
            isFullscreen={isFullscreen}
          ></Nav>
          <FadeMenu show={menuIsVisible}>
            <Menu
              closeMenu={() => setMenuIsVisible(false)}
              location={location}
            />
          </FadeMenu>
          {children}
        </Fullscreen>
      </AppProvider>
    </LocaleContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
